import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/:type(especialista|clinica)',
    name: 'Home',
    component: () => import('@/views/EncontreView.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'Error 404',
    component: () => import('@/views/Error404.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
